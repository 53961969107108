import React from 'react';
import './app.css';

export function TermsOfUse(): JSX.Element {
    return (
        <>
            <h1>Terms of Use</h1>
        </>
    );
}
