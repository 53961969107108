import React, { createContext, ReactNode, useContext, useState } from 'react';
import { UserAgentApplication, Configuration } from 'msal';
import environment from '../environments/environment';
import { TeamsContext } from './teams-context';

export const AuthContext = createContext<IAuthContext>(null!);

export default function AuthContextProvider(props: IAuthProviderProps): JSX.Element {
    const teamsContext = useContext(TeamsContext);

    const msalConfig: Configuration = {
        auth: {
            clientId: props.config.clientID,
            authority: props.config.authority,
        },
        cache: {
            cacheLocation: 'localStorage',
        },
    };
    const defaultScopes = props.config.scopes.graph;
    const [app] = useState(new UserAgentApplication(msalConfig));

    app.handleRedirectCallback((error) => {
        if (error) {
            localStorage.clear();
            console.log(error);
        }
    });

    function getUserProfile(): IAuthProfile | null {
        const profile = app.getAccount();
        if (!profile || !profile.idToken) {
            return null;
        }

        const alias = getAliasFromUsername(profile.userName);
        let roles: string[] = [];
        const tokenRoles = profile.idToken['roles'];
        if (tokenRoles && typeof tokenRoles === 'object') {
            const roleValues: string[] = Object.values(tokenRoles);
            if (roleValues.length > 0) {
                roles = roleValues;
            }
        }

        return {
            name: profile.name,
            alias: alias,
            email: profile.userName,
            roles: roles,
            tenantId: profile.idToken.tid,
        };
    }

    function getAliasFromUsername(username: string): string {
        for (const domain of props.config.whitelistedDomains) {
            const pattern = new RegExp(domain);
            if (pattern.test(username)) {
                username = username.replace(pattern, '');
                break;
            }
        }

        return username;
    }

    async function getToken(scopes: string[] = defaultScopes): Promise<string | null> {
        const context = await teamsContext.getTeamsContext();

        const request = {
            scopes: scopes,
            loginHint: context.loginHint,
        };
        try {
            const result = await app.acquireTokenSilent(request);
            return result.accessToken;
        } catch (error) {
            await app.loginRedirect(request);
            console.log(error);
            return null;
        }
    }

    return (
        <AuthContext.Provider
            value={{
                getUserProfile: getUserProfile,
                getToken: getToken,
            }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export interface IAuthContext {
    getUserProfile: () => IAuthProfile | null;
    getToken: (scopes?: string[]) => Promise<string | null>;
}

export interface IAuthProfile {
    name: string;
    alias: string;
    email: string;
    roles: string[];
    tenantId: string;
}

export interface IAuthProviderProps {
    config: typeof environment.aadConfig;
    children: ReactNode;
}
