import { IAuthContext } from '../../contexts/auth-context';
import { AUTH_PREFIX, JSON_CONTENT_TYPE } from '../http-options';
import { IGetChatMembersResponse, IGetUsersByIdResponse, UserFilter } from './graph-models';
import config from '../../environments/environment';

export class GraphClient {
    public static async getUsersById(
        authContext: IAuthContext,
        userIds: string[],
        filters: UserFilter[] = [],
    ): Promise<IGetUsersByIdResponse> {
        const httpOptions = await this._getHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify({
            ids: userIds,
        });

        const url = new URL(`${config.graphServiceConfig.baseUrl}/users/getByIds`);

        if (filters.length > 0) {
            url.searchParams.append('$select', filters.join(','));
        }

        const result = await fetch(url.toString(), httpOptions);
        if (result.ok) {
            return result.json();
        } else {
            throw result;
        }
    }

    public static async getChatMembers(
        authContext: IAuthContext,
        chatId: string,
    ): Promise<IGetChatMembersResponse> {
        const httpOptions = await this._getHttpOptions(authContext);

        const url = new URL(`${config.graphServiceConfig.baseUrl}/chats/${chatId}/members`);

        const result = await fetch(url.toString(), httpOptions);
        if (result.ok) {
            return result.json();
        } else {
            throw result;
        }
    }

    public static async removeChatMembers(
        authContext: IAuthContext,
        chatId: string,
        membershipId: string,
    ): Promise<void> {
        const httpOptions = await this._getHttpOptions(authContext);

        httpOptions.method = 'DELETE';
        const url = new URL(
            `${config.graphServiceConfig.baseUrl}/chats/${chatId}/members/${membershipId}`,
        );

        const result = await fetch(url.toString(), httpOptions);
        if (result.ok) {
            return;
        } else {
            throw new Error(`Failed to remove chat member: ${result.status} ${result.statusText}`);
        }
    }

    private static async _getHttpOptions(context: IAuthContext): Promise<RequestInit> {
        const token = await context.getToken();

        return {
            headers: {
                Authorization: `${AUTH_PREFIX} ${token}`,
                'Content-Type': JSON_CONTENT_TYPE,
            },
        };
    }
}
