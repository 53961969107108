import { createTheme } from '@fluentui/react';
import { globalColors } from './global-colors';
import { ITheme } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';

export const defaultTheme = createTheme();

export const dropdownDarkTheme = createTheme({
    palette: {
        themePrimary: globalColors.backgroundGray,
        themeDark: globalColors.hoverGray,
        neutralPrimary: globalColors.white,
        neutralSecondary: globalColors.white,
    },
    semanticColors: {
        inputBackground: globalColors.backgroundGray,
        inputBackgroundChecked: globalColors.backgroundGray,
        inputForegroundChecked: globalColors.white,
        menuBackground: globalColors.backgroundGray,
        menuItemBackgroundPressed: globalColors.selectedGray,
        menuItemBackgroundHovered: globalColors.backgroundGray,
        menuItemText: globalColors.white,
        menuItemTextHovered: globalColors.white,
    },
    defaultFontStyle: {
        color: globalColors.white,
    },
});

export const darkTheme = createTheme({
    palette: {
        neutralLighter: '#313131',
        neutralLight: '#3f3f3f',
        neutralQuaternaryAlt: '#484848',
        neutralQuaternary: '#4f4f4f',
        neutralTertiaryAlt: '#6d6d6d',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: globalColors.white,
        white: globalColors.black,
    },
    semanticColors: {
        listBackground: globalColors.black,
        menuBackground: globalColors.black,
        bodyBackground: globalColors.black,
        menuItemBackgroundPressed: globalColors.black,
    },
    defaultFontStyle: {
        color: globalColors.white,
    },
});

export interface IThemeColors {
    text: string;
    background: string;
    hyperlink: string;
}

const lightThemeColors: IThemeColors = {
    text: NeutralColors.black,
    background: NeutralColors.white,
    hyperlink: globalColors.hyperlinkDarkBlue,
};

export const darkThemeColors: IThemeColors = {
    text: NeutralColors.white,
    background: NeutralColors.black,
    hyperlink: globalColors.hyperlinkLightBlue,
};

export let themeColors: IThemeColors = lightThemeColors;
export let theme: ITheme = defaultTheme;

export function setTheme(themeName: string | undefined): void {
    if (!themeName || themeName === 'default') {
        themeColors = lightThemeColors;
        theme = defaultTheme;
    } else {
        themeColors = darkThemeColors;
        theme = darkTheme;
    }
}
