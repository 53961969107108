import React, { useState } from 'react';
import '../app.css';
import { mergeStyleSets } from '@fluentui/react';
import { TabInfoCard } from '../pre-meeting-tab/tab-info-card';
import { NeutralColors } from '@fluentui/theme';
import { ITabProps } from '../tab/tab-models';
import { globalColors } from '../../assets/styles/global-colors';
import { GroupsMembershipTable } from './groups-membership-table';
import logo from '../../assets/img/logo.png';
import { themeColors } from '../../assets/styles/themes';

export function PreMeetingTab(props: ITabProps): JSX.Element {
    const [errorMessage, setErrorMessage] = useState<string>();

    if (props.errorMessage) {
        return <div className={styles.errorMessage}>{props.errorMessage}</div>;
    }

    if (errorMessage) {
        return <div className={styles.errorMessage}>{errorMessage}</div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={logo} />
                <div className={styles.titleTextContainer} style={{ color: themeColors.text }}>
                    <h2 className={styles.title}>Personnel</h2>
                    <h2 className={styles.subtitle}>Group Check</h2>
                </div>
            </div>
            <div className={styles.groupsMembershipTableContainer}>
                <GroupsMembershipTable
                    compliantGroups={props.compliantGroups}
                    setErrorMessage={setErrorMessage}
                    chatId={props.teamsContext.chatId}
                />
            </div>
            <div className={styles.infoCardContainer}>
                <TabInfoCard />
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    errorMessage: {
        color: globalColors.errorRed,
        padding: '25px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px',
        flexWrap: 'wrap',
    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        marginBottom: '20px',
    },
    logo: {
        height: '50px',
        width: '50px',
        alignSelf: 'center',
        paddingRight: '15px',
    },
    titleTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    title: {
        fontSize: '24px',
        lineHeight: '20px',
        margin: '0',
        marginBottom: '8px',
    },
    subtitle: {
        fontSize: '16px',
        lineHeight: '19px',
        margin: '0',
    },
    groupsMembershipTableContainer: {
        display: 'flex',
    },
    infoCardContainer: {
        backgroundColor: NeutralColors.gray30,
        display: 'flex',
        maxWidth: '600px',
        borderRadius: '20px',
        marginTop: '20px',
    },
});
