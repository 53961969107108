import React, { createContext, ReactNode, useState } from 'react';
import { Context } from '@microsoft/teams-js';
import * as microsoftTeams from '@microsoft/teams-js';

export const TeamsContext = createContext<ITeamsContext>(null!);

export default function TeamsContextProvider(props: IAuthProviderProps): JSX.Element {
    const [teamsContext, setTeamsContext] = useState<microsoftTeams.Context>();

    async function getTeamsContext(): Promise<Context> {
        if (!teamsContext) {
            return new Promise((resolve) => {
                microsoftTeams.getContext((context: microsoftTeams.Context) => {
                    setTeamsContext(context);
                    resolve(context);
                });
            });
        }
        return Promise.resolve(teamsContext!);
    }

    return (
        <TeamsContext.Provider
            value={{
                getTeamsContext: getTeamsContext,
            }}>
            {props.children}
        </TeamsContext.Provider>
    );
}

export interface ITeamsContext {
    getTeamsContext: () => Promise<Context>;
}

export interface IAuthProviderProps {
    children: ReactNode;
}
