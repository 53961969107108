import React from 'react';
import { mergeStyleSets } from 'office-ui-fabric-react';
import { globalColors } from '../../assets/styles/global-colors';

export interface IGroupComplianceStatBoxProps {
    label: string;
    count?: number;
    color: string;
    onClick: React.MouseEventHandler;
}

export function GroupComplianceStatBox(props: IGroupComplianceStatBoxProps): JSX.Element {
    const styles = mergeStyleSets({
        container: {
            display: 'flex',
            backgroundColor: globalColors.backgroundGray,
            flexDirection: 'column',
            width: '85px',
            height: '112px',
            alignItems: 'center',
            textAlign: 'center',
            cursor: 'pointer',
        },
        label: {
            display: 'flex',
            color: globalColors.white,
            height: '50px',
            alignItems: 'center',
            fontWeight: 'bold',
        },
        count: {
            color: globalColors.white,
            fontSize: '30px',
            lineHeight: '40px',
            paddingBottom: '10px',
        },
        bottomColorContainer: {
            backgroundColor: props.color,
            height: '10px',
            width: 'inherit',
        },
    });

    return (
        <div onClick={props.onClick} className={styles.container}>
            <div className={styles.label}>{props.label}</div>
            <div className={styles.count}>{props.count}</div>
            <div className={styles.bottomColorContainer} />
        </div>
    );
}
