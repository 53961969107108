export enum GroupMemberStatus {
    NotApproved = 'NOT_APPROVED',
    Approved = 'APPROVED',
    Quarantined = 'QUARANTINED',
}

export interface IPagedResult<T> {
    results: T[];
    continuationToken: string;
}

export interface IGroupBasic {
    id: string;
    name: string;
    description: string;
}

export interface IGroup extends IGroupBasic {
    metrics: IGroupMetrics;
}

export interface IGroupMetrics {
    memberCount: number;
    violationCount: number;
    requestCount: number;
    onGracePeriodCount: number;
    inAllowListCount: number;
}
