import { IGroupBasic } from '../clients/groups/groups-model';

const groupSort = (group1: IGroupBasic, group2: IGroupBasic) => {
    const groupNameLower1 = group1.name.toLocaleLowerCase();
    const groupNameLower2 = group2.name.toLocaleLowerCase();
    if (groupNameLower1 < groupNameLower2) {
        return -1;
    }
    if (groupNameLower1 > groupNameLower2) {
        return 1;
    }
    return 0;
};

export default groupSort;
