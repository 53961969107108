import { GroupMemberStatus, IGroup, IGroupBasic, IPagedResult } from './groups-model';
import config from '../../environments/environment';
import { IAuthContext } from '../../contexts/auth-context';
import { AUTH_PREFIX } from '../http-options';
import environment from '../../environments/environment';

export class GroupsClient {
    public static async getMyGroups(
        authContext: IAuthContext,
        includeMetrics: boolean,
        continuationToken?: string,
        statuses?: GroupMemberStatus[],
        pageSize?: number,
    ): Promise<IPagedResult<IGroup>> {
        const httpOptions = await this._getHttpOptions(authContext);
        if (continuationToken) {
            (httpOptions.headers as { [key: string]: string })['x-continuation-token'] =
                continuationToken;
        }

        const url = new URL(`${config.groupServiceConfig.baseUrl}group/memberships/me`);

        if (includeMetrics) {
            url.searchParams.append('includeMetrics', includeMetrics.toString());
        }

        if (statuses) {
            url.searchParams.append('statuses', statuses.join(','));
        }

        if (pageSize) {
            url.searchParams.append('pageSize', pageSize.toString());
        }

        const result = await fetch(url.toString(), httpOptions);
        if (result.ok) {
            return result.json();
        } else {
            throw result;
        }
    }

    public static async getGroup(authContext: IAuthContext, groupId: string): Promise<IGroup> {
        const httpOptions = await this._getHttpOptions(authContext);

        const url = new URL(`${config.groupServiceConfig.baseUrl}groups/${groupId}`);

        const result = await fetch(url.toString(), httpOptions);
        if (result.ok) {
            return result.json();
        } else {
            throw result;
        }
    }

    public static async getGroupBasic(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupBasic> {
        const httpOptions = await this._getHttpOptions(authContext);

        const url = new URL(`${config.groupServiceConfig.baseUrl}groups/${groupId}/basic`);

        const result = await fetch(url.toString(), httpOptions);
        if (result.ok) {
            return result.json();
        } else {
            throw result;
        }
    }

    public static async checkGroupMemberships(
        authContext: IAuthContext,
        groupId: string,
        personnelIds: string[],
    ): Promise<string[]> {
        const httpOptions = await this._getHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(personnelIds);

        const url = new URL(`${config.groupServiceConfig.baseUrl}group/${groupId}/membercheck`);

        const result = await fetch(url.toString(), httpOptions);
        if (result.ok) {
            return result.json();
        } else {
            throw result;
        }
    }

    private static async _getHttpOptions(authContext: IAuthContext): Promise<RequestInit> {
        const scopes = environment.aadConfig.scopes.ags;
        const token = await authContext.getToken(scopes);
        return {
            headers: {
                Authorization: AUTH_PREFIX + token,
                'x-scope': 'ags',
                'Content-Type': 'application/json',
            },
        };
    }
}
