import React from 'react';
import { mergeStyleSets } from '@fluentui/react';
import { globalColors } from '../../assets/styles/global-colors';
import environment from '../../environments/environment';
import { themeColors } from '../../assets/styles/themes';

export function TabInfoCard(): JSX.Element {
    return (
        <div
            className={styles.container}
            style={{ backgroundColor: themeColors.background, color: themeColors.text }}>
            <div className={styles.containerLeft}>
                <h2 className={styles.sectionHeader}>Protect Project Information</h2>
                <p className={styles.sectionBody}>
                    Every employee has an individual responsibility to protect sensitive information
                    on behalf of our customers. Always check that meeting participants are compliant
                    members of the project before it is discussed.
                </p>
                <h3 className={styles.sectionSubheader}>Be Alert. Be Aware. Be Assertive.</h3>
                <p>
                    Submit feedback{' '}
                    <a
                        className={styles.url}
                        href={environment.feedbackFormUrl}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <u>here.</u>
                    </a>
                    <br />
                    <i>For support contact personnel_pm@microsoft.com</i>
                </p>
            </div>
            <div className={styles.containerRight}>
                <h2 className={styles.sectionHeader}>Manage My Groups</h2>
                <p className={styles.sectionBody}>
                    View my policy compliance, nominate new group members, and leave groups{' '}
                    <a
                        className={styles.url}
                        href='https://personnel.microsoft.com/groups'
                        target='_blank'
                        rel='noopener noreferrer'>
                        <u>here.</u>
                    </a>
                </p>
                <br />
                <div className={styles.additionalResourceContainer}>
                    <h3 className={styles.resourceTitle}>Cloud Screening</h3>
                    <a
                        style={{ color: themeColors.hyperlink }}
                        href='https://aka.ms/screening'
                        target='_blank'
                        rel='noopener noreferrer'>
                        https://aka.ms/screening
                    </a>
                    <h3 className={styles.resourceTitle}>My Attributes</h3>
                    <a
                        style={{ color: themeColors.hyperlink }}
                        href='https://personnel.microsoft.com/profile/user/me/attributes'
                        target='_blank'
                        rel='noopener noreferrer'>
                        https://personnel.microsoft.com/profile/user/me/attributes
                    </a>
                    <h3 className={styles.resourceTitle}>My Eligibilities</h3>
                    <a
                        style={{ color: themeColors.hyperlink }}
                        href='https://personnel.microsoft.com/profile/user/me/eligibilities'
                        target='_blank'
                        rel='noopener noreferrer'>
                        https://personnel.microsoft.com/profile/user/me/eligibilities
                    </a>
                </div>
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px 40px',
    },
    containerLeft: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '600px',
    },
    containerRight: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '600px',
    },
    sectionHeader: {
        fontSize: '24px',
        lineHeight: '28px',
        marginTop: '0',
        marginBottom: '15x',
        color: globalColors.indigo,
    },
    sectionSubheader: {
        color: globalColors.indigo,
    },
    sectionBody: {
        marginTop: '0',
        marginBottom: '10px',
    },
    additionalResourceContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    resourceTitle: {
        color: globalColors.indigo,
        marginTop: '0',
        marginBottom: '0',
    },
    url: {
        color: 'inherit',
    },
});
