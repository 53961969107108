import { IAuthContext } from '../../contexts/auth-context';
import { IEmployee } from './employee-model';
import config from '../../environments/environment';
import { AUTH_PREFIX, JSON_CONTENT_TYPE } from '../http-options';
import environment from '../../environments/environment';

let getEmployeeCache: { [key: string]: IEmployee } = {};
initCache();

function initCache(): void {
    const getEmployeeCacheStr = sessionStorage.getItem('getEmployeeCache') || '{}';
    getEmployeeCache = JSON.parse(getEmployeeCacheStr);
}

function updateSessionCache(cacheName: string): void {
    sessionStorage.setItem(cacheName, JSON.stringify(eval(cacheName)));
}

export class EmployeeClient {
    public static async getEmployeeByAlias(
        authContext: IAuthContext,
        alias: string,
    ): Promise<IEmployee> {
        if (alias in getEmployeeCache) {
            return Promise.resolve(getEmployeeCache[alias]);
        }

        const httpOptions = await this._getHttpOptions(authContext);
        const url = config.employeeServiceConfig.baseUrl + 'employee/' + alias;
        const res = await fetch(url, httpOptions);

        if (res.status === 200) {
            const ret = await res.json();
            getEmployeeCache[alias] = ret;
            updateSessionCache('getEmployeeCache');
            return ret;
        } else {
            throw res;
        }
    }

    private static async _getHttpOptions(
        context: IAuthContext,
        contentType?: string,
        continuationToken?: string,
    ): Promise<RequestInit> {
        const scopes = environment.aadConfig.scopes.ags;
        return {
            headers: {
                Authorization: AUTH_PREFIX + (await context.getToken(scopes)),
                'Content-Type': contentType ? contentType : JSON_CONTENT_TYPE,
                'x-continuation-token': continuationToken ? continuationToken : '',
            },
        };
    }
}
