import { RefObject, useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

function useOnMouseOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (event: AnyEvent) => void,
): void {
    useEffect(() => {
        const listener = (event: AnyEvent) => {
            const el = ref?.current;

            // Do nothing if clicking ref's element or descendent elements
            if (!el) {
                return;
            } else {
                if (el.contains && el.contains(event.target as Node)) {
                    return;
                }
            }

            handler(event);
        };

        document.addEventListener(`mouseleave`, listener);

        return () => {
            document.removeEventListener(`mouseleave`, listener);
        };

        // Reload only if ref or handler changes
    }, [ref, handler]);
}

export default useOnMouseOutside;
