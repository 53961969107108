import { GraphScope } from '../clients/graph/graph-models';

export interface IReactAppConfig {
    baseUrl: string;
    tabUrl: string;
    configUrl: string;
    sidePanelUrl: string;
}

export interface IAadConfig {
    clientID: string;
    authority: string;
    whitelistedDomains: string[];
    redirectUri: string;
    scopes: {
        graph: string[];
        ags: string[];
    };
}

export interface IServiceConfig {
    baseUrl: string;
}

export interface IConfig {
    reactAppConfig: IReactAppConfig;
    aadConfig: IAadConfig;
    applicationInsightsKey: string;
    applicationInsightsRoleName: string;
    graphServiceConfig: IServiceConfig;
    employeeServiceConfig: IServiceConfig;
    groupServiceConfig: IServiceConfig;
    feedbackFormUrl: string;
    teamsAddinDisplaySuffix: string;
    clarityTag: string;
}

export interface IEnvConfig {
    personnelAppId: string;
    personnelPortalEndpoint: string;
    personnelApiEndpoint: string;
    appInsightsKey: string;
    teamsAddinDisplaySuffix: string;
    authorityId: string;
    teamsAddinAadClientId: string;
    clarityTag: string;
    loginEndpoint: string;
    allowedDomains: string[];
    graphApiEndpoint: string;
    feedbackFormUrl: string;
}

const config: IConfig = {
    reactAppConfig: {
        baseUrl: `https://${location.hostname}/`,
        tabUrl: `https://${location.hostname}/tab`,
        configUrl: `https://${location.hostname}/config`,
        sidePanelUrl: `https://${location.hostname}/sidepanel`,
    },
    aadConfig: {
        clientID: '',
        authority: '',
        whitelistedDomains: [],
        redirectUri: '',
        scopes: {
            graph: [GraphScope.UserReadAll, GraphScope.ChatReadWrite],
            ags: [],
        },
    },
    applicationInsightsKey: '',
    applicationInsightsRoleName: 'Teams Group Check App',
    graphServiceConfig: {
        baseUrl: '',
    },
    employeeServiceConfig: {
        baseUrl: '',
    },
    groupServiceConfig: {
        baseUrl: '',
    },
    feedbackFormUrl: '',
    teamsAddinDisplaySuffix: '',
    clarityTag: '',
};

export async function loadConfig(): Promise<void> {
    const envConfig = (await (await fetch('/api/config')).json()) as IEnvConfig;
    config.aadConfig.clientID = envConfig.teamsAddinAadClientId;
    config.aadConfig.authority = `${envConfig.loginEndpoint}/${envConfig.authorityId}/`;
    config.aadConfig.whitelistedDomains = envConfig.allowedDomains;
    config.aadConfig.redirectUri = `https://${location.hostname}/config`;
    config.aadConfig.scopes.ags = [`api://${envConfig.personnelAppId}/user_impersonation`];
    config.applicationInsightsKey = envConfig.appInsightsKey;
    config.graphServiceConfig.baseUrl = envConfig.graphApiEndpoint;
    config.employeeServiceConfig.baseUrl = `${envConfig.personnelApiEndpoint}/v1/eds/`;
    config.groupServiceConfig.baseUrl = `${envConfig.personnelApiEndpoint}/v1/grp/`;
    config.feedbackFormUrl = envConfig.feedbackFormUrl;
    config.teamsAddinDisplaySuffix = envConfig.teamsAddinDisplaySuffix;
    config.clarityTag = envConfig.clarityTag;
}

// Load config is called and awaited in index.tsx before any other code runs, so config should always be filled out.
export default config;
