/* eslint-disable prefer-rest-params */
export const InitClarity = (clarityTag: string): void => {
    (function (
        c: any,
        l: Document,
        a: 'clarity',
        r: 'script',
        i: string,
        t?: HTMLScriptElement,
        y?: Element,
    ): void {
        c[a] =
            c[a] ||
            function (): void {
                (c[a].q = c[a].q || []).push(arguments);
            };
        t = l.createElement(r);
        t.async = true;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y?.parentNode?.insertBefore(t, y);
    })(window, document, 'clarity', 'script', clarityTag);
};
