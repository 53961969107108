import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
    Checkbox,
    CheckboxVisibility,
    Customizer,
    DefaultButton,
    DetailsList,
    IColumn,
    Icon,
    IDetailsColumnStyleProps,
    IDetailsColumnStyles,
    IDetailsListCheckboxProps,
    IDetailsRowStyleProps,
    IDetailsRowStyles,
    IStyleFunctionOrObject,
    mergeStyleSets,
    PrimaryButton,
    Selection,
    SelectionMode,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { IGroupBasic } from '../../clients/groups/groups-model';
import { PersonnelService } from '../../services/personnel-service';
import { AuthContext } from '../../contexts/auth-context';
import { GroupService } from '../../services/group-service';
import { IParticipantGroupMembership, ITabGroupData } from '../tab/tab-models';
import { globalColors } from '../../assets/styles/global-colors';
import { theme, themeColors } from '../../assets/styles/themes';
import { GraphClient } from '../../clients/graph/graph-client';

export interface GroupsSelectorProps {
    compliantGroups: IGroupBasic[];
    setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
    chatId: string | undefined;
}

export function GroupsMembershipTable(props: GroupsSelectorProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [personnelService] = useState<PersonnelService>(new PersonnelService(authContext));
    const [groupService] = useState<GroupService>(new GroupService(authContext, personnelService));

    const [selectedGroups, setSelectedGroups] = useState<ITabGroupData[]>();
    const [groupMemberships, setGroupMemberships] = useState<IParticipantGroupMembership[]>();
    const [isLoadingGroupMemberships, setIsLoadingGroupMemberships] = useState<boolean>(false);
    const [isRemovingNonMembers, setIsRemovingNonMembers] = useState<boolean>(false);
    const [compliantMembersCount, setCompliantMembersCount] = useState<number>(0);
    const [selection] = useState<Selection>(new Selection());

    useEffect(() => {
        async function loadGroupMemberships(): Promise<void> {
            try {
                if (props.chatId) {
                    const chatGraphUserIds = await personnelService.getChatParticipantUserIds(
                        props.chatId,
                    );

                    if (selectedGroups && selectedGroups.length > 0) {
                        const selectedGroupIds = selectedGroups.map((group) => group.group.id);

                        await groupService.setGroupsComplianceData(
                            selectedGroupIds,
                            chatGraphUserIds,
                        );

                        const filteredGraphUserIds =
                            personnelService.getGraphUserIdsForUsersWithPersonnelData(
                                chatGraphUserIds,
                            );

                        const newGroupMemberships = filteredGraphUserIds.map(
                            (graphUserId): IParticipantGroupMembership => {
                                const personnelData =
                                    personnelService.getExistingPersonnelDataFromGraphUserId(
                                        graphUserId,
                                    );
                                return {
                                    personnel: personnelData!,
                                    groupCompliance:
                                        groupService.getExistingGroupComplianceDataForUser(
                                            graphUserId,
                                            selectedGroupIds,
                                        ),
                                };
                            },
                        );

                        newGroupMemberships.sort((groupMembership1, groupMembership2) => {
                            if (
                                groupMembership1.personnel.displayName <
                                groupMembership2.personnel.displayName
                            ) {
                                return -1;
                            }
                            if (
                                groupMembership1.personnel.displayName >
                                groupMembership2.personnel.displayName
                            ) {
                                return 1;
                            }

                            return 0;
                        });

                        setGroupMemberships(newGroupMemberships);
                        setCountForCompliantMembers();
                        setIsLoadingGroupMemberships(false);
                    }
                }
            } catch (e) {
                props.setErrorMessage('Failed to retrieve group memberships');
                console.log('error: ', e);
            }
        }

        if (props.chatId && selectedGroups && selectedGroups.length > 0) {
            loadGroupMemberships();
        }
    }, [selectedGroups, compliantMembersCount]);

    function getGroupsSelectorColumns(): IColumn[] {
        return [
            {
                key: 'column0',
                name: 'Select Groups:',
                minWidth: 200,
                styles: groupsSelectorHeaderStyles,
                className: styles.groupName,
                headerClassName: styles.groupSelectorHeader,
                onRender: (group: IGroupBasic): string => group.name,
            },
        ];
    }

    function getParticipantGroupMembershipTableColumns(): IColumn[] {
        if (!selectedGroups) {
            return [];
        }

        const columns: IColumn[] = [
            {
                key: 'column0',
                name: 'Participant Name',
                minWidth: 200,
                styles: tableParticipantHeaderStyles,
                className: styles.participantName,
                headerClassName: styles.tableHeader,
                onRender: (groupMembership: IParticipantGroupMembership): string =>
                    groupMembership.personnel.displayName,
            },
        ];

        const groupColumns = selectedGroups.map((selectedGroupData, index) => {
            return {
                key: `column${index + 1}`,
                name: selectedGroupData.group.name,
                minWidth: 150,
                styles: tableGroupHeaderStyles,
                headerClassName: styles.tableHeader,
                onRender: (groupMembership: IParticipantGroupMembership): JSX.Element => {
                    if (groupMembership.groupCompliance[selectedGroupData.group.id]) {
                        return (
                            <div className={styles.complianceIconContainer}>
                                <Icon
                                    className={`${styles.complianceIcon} ${styles.isCompliantIcon}`}
                                    iconName='StatusCircleCheckmark'
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div className={styles.complianceIconContainer}>
                                <Icon
                                    className={`${styles.complianceIcon} ${styles.notCompliantIcon}`}
                                    iconName='StatusCircleErrorX'
                                />
                            </div>
                        );
                    }
                },
            };
        });

        return columns.concat(groupColumns);
    }

    async function applySelectedGroupChanges(): Promise<void> {
        const selectedGroups = [...selection.getSelection()] as IGroupBasic[];
        const selectedGroupIds = selectedGroups.map((group) => group.id);
        setIsLoadingGroupMemberships(true);
        try {
            const newSelectedGroups = await Promise.all(
                selectedGroupIds.map((groupId) => {
                    return groupService.getGroupData(groupId);
                }),
            );
            setSelectedGroups(newSelectedGroups);
        } catch (e) {
            props.setErrorMessage(
                'Failed to retrieve group data, please navigate away from Group Check tab and back to refresh',
            );
            console.log(e);
            setIsLoadingGroupMemberships(false);
        }
    }

    async function setCountForCompliantMembers() {
        const chatMemberResponse = await GraphClient.getChatMembers(authContext, props.chatId!);
        const nonCompliantUserIds = getNonCompliantUserIdsFromSelectedGroups();

        const tempNonCompliantUserIds = new Set<string>();
        for (const chatMember of chatMemberResponse.value.filter((chatMember) =>
            nonCompliantUserIds.includes(chatMember.userId),
        )) {
            tempNonCompliantUserIds.add(chatMember.userId);
        }

        const chatMemberCount = chatMemberResponse.value.length;
        const compliantMembersCount = chatMemberCount - tempNonCompliantUserIds.size;
        setCompliantMembersCount(compliantMembersCount);

        if (compliantMembersCount < 2) {
            setIsRemovingNonMembers(true);
        } else {
            setIsRemovingNonMembers(false);
        }
    }

    const onRenderCheckbox = (props?: IDetailsListCheckboxProps) => {
        return (
            <div style={{ pointerEvents: 'none' }}>
                <Checkbox checked={props?.checked} />
            </div>
        );
    };

    function getNonCompliantUserIdsFromSelectedGroups(): string[] {
        const nonComplaintUserIds: string[] = [];
        selectedGroups?.forEach((selectedGroup) => {
            groupMemberships?.forEach((groupMember) => {
                if (!groupMember.groupCompliance[selectedGroup.group.id]) {
                    nonComplaintUserIds.push(groupMember.personnel.userId);
                }
            });
        });
        return nonComplaintUserIds;
    }

    async function removeNonMembers(): Promise<void> {
        setIsRemovingNonMembers(true);

        const chatId = props.chatId;
        const chatMemberResponse = await GraphClient.getChatMembers(authContext, chatId!);
        const nonCompliantUserIds = getNonCompliantUserIdsFromSelectedGroups();
        const updatedGroupMemberships = groupMemberships ? [...groupMemberships] : [];

        for (const chatMember of chatMemberResponse.value.filter((chatMember) =>
            nonCompliantUserIds.includes(chatMember.userId),
        )) {
            try {
                await GraphClient.removeChatMembers(authContext, chatId!, chatMember.id);
                // Update the group memberships list.
                const index = updatedGroupMemberships.findIndex(
                    (member) => member.personnel.userId === chatMember.userId,
                );
                if (index !== -1 && updatedGroupMemberships[index]) {
                    updatedGroupMemberships.splice(index, 1);
                }
            } catch (e) {
                props.setErrorMessage('Failed to remove chat member');
            }
        }

        setGroupMemberships(updatedGroupMemberships);
        setIsRemovingNonMembers(false);
    }

    const component = (
        <div className={styles.container} style={{ backgroundColor: themeColors.background }}>
            <div className={styles.groupsSelectorContainer}>
                <Customizer settings={{ theme: theme }}>
                    <DetailsList
                        items={props.compliantGroups}
                        columns={getGroupsSelectorColumns()}
                        selectionPreservedOnEmptyClick={true}
                        checkboxVisibility={CheckboxVisibility.always}
                        selection={selection}
                        onRenderCheckbox={onRenderCheckbox}
                    />
                </Customizer>
                <PrimaryButton
                    className={styles.applyButton}
                    text={isLoadingGroupMemberships ? 'Loading...' : 'Apply'}
                    onClick={applySelectedGroupChanges}
                    disabled={isLoadingGroupMemberships}
                />
            </div>
            {isLoadingGroupMemberships ? (
                <div className={styles.loadingContainer}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            ) : (
                selectedGroups &&
                selectedGroups.length > 0 && (
                    <div className={styles.participantGroupMembershipTableContainer}>
                        <Customizer settings={{ theme: theme }}>
                            <ShimmeredDetailsList
                                items={groupMemberships || []}
                                columns={getParticipantGroupMembershipTableColumns()}
                                selectionMode={SelectionMode.none}
                                enableShimmer={isLoadingGroupMemberships}
                                onRenderRow={(props, defaultRender): JSX.Element | null => {
                                    if (props && defaultRender) {
                                        return defaultRender({
                                            ...props,
                                            styles: tableRowStyles,
                                        });
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Customizer>
                        <div style={{ padding: '8px 7px 10px 7px' }}>
                            <DefaultButton
                                iconProps={{ iconName: 'UserRemove' }}
                                style={{ width: '100%' }}
                                disabled={isRemovingNonMembers}
                                onClick={removeNonMembers}>
                                Remove non-members
                            </DefaultButton>
                            {compliantMembersCount === 1 && (
                                <div style={{ marginTop: '8px', color: 'red' }}>
                                    You are the only compliant user for this combination.
                                </div>
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );

    return component;
}

const styles = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    groupsSelectorContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '400px',
        paddingRight: '30px',
    },
    groupSelectorHeader: {
        pointerEvents: 'none',
        position: 'relative',
        top: '7px',
    },
    groupName: {
        alignSelf: 'center',
        fontSize: '15px',
    },
    applyButton: {
        alignSelf: 'flex-end',
        marginTop: '20px',
        marginBottom: '20px',
    },
    tableHeader: {
        pointerEvents: 'none',
        borderBottom: `1px solid ${NeutralColors.gray100}`,
    },
    participantGroupMembershipTableContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '50px',
    },
    loadingContainer: {
        width: '100%',
        paddingTop: '50px',
        textAlign: 'center',
        paddingRight: '20px',
    },
    participantName: {
        alignSelf: 'center',
        fontSize: '15px',
        lineHeight: '18px',
        fontWeight: 'bold',
    },
    complianceIconContainer: {
        textAlign: 'center',
    },
    complianceIcon: {
        fontSize: '35px',
        position: 'relative',
        bottom: '10px',
    },
    isCompliantIcon: {
        color: globalColors.compliantGreen,
    },
    notCompliantIcon: {
        color: globalColors.notCompliantRed,
    },
});

const groupsSelectorHeaderStyles: IStyleFunctionOrObject<
    IDetailsColumnStyleProps,
    IDetailsColumnStyles
> = {
    cellName: {
        fontSize: '18px',
        whiteSpace: 'initial',
        lineHeight: '21px',
        verticalAlign: 'middle',
    },
};

const tableParticipantHeaderStyles: IStyleFunctionOrObject<
    IDetailsColumnStyleProps,
    IDetailsColumnStyles
> = {
    cellName: {
        fontSize: '18px',
        whiteSpace: 'initial',
        lineHeight: '21px',
    },
};

const tableGroupHeaderStyles: IStyleFunctionOrObject<
    IDetailsColumnStyleProps,
    IDetailsColumnStyles
> = {
    cellName: {
        fontSize: '18px',
        whiteSpace: 'initial',
        lineHeight: '21px',
        textAlign: 'center',
    },
    cellTitle: {
        justifyContent: 'center',
    },
};

const tableRowStyles: IStyleFunctionOrObject<IDetailsRowStyleProps, IDetailsRowStyles> = {
    root: {
        pointerEvents: 'none',
        borderBottom: `1px solid ${NeutralColors.gray100}`,
        height: '20px',
    },
};
