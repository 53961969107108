import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { EmployeeClient } from '../clients/employee/employee-client';
import { IEmployee } from '../clients/employee/employee-model';
import { AuthContext, IAuthProfile } from './auth-context';

export const UserContext = createContext<IUserContext>(null!);

export default function UserContextProvider(props: IUserProviderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [msalUser] = useState(authContext.getUserProfile());
    const [employeeRecord, setEmployeeRecord] = useState<IEmployee>();
    const [user, setUser] = useState(merge(msalUser, employeeRecord));

    useEffect(() => {
        async function getEmployee(): Promise<void> {
            try {
                if (msalUser && msalUser.alias) {
                    const employee = await EmployeeClient.getEmployeeByAlias(
                        authContext,
                        msalUser.alias,
                    );
                    setEmployeeRecord(employee);
                }
            } catch (e) {
                console.log('error: ', e);
            }
        }

        getEmployee();
    }, [authContext, msalUser]);

    useEffect(() => {
        setUser(merge(msalUser, employeeRecord));
    }, [employeeRecord]);

    function merge(msalUser: IAuthProfile | null, employeeRecord: IEmployee | undefined): IUser {
        if (msalUser && employeeRecord) {
            return Object.assign(msalUser, employeeRecord);
        }
        return msalUser as IUser;
    }

    return (
        <UserContext.Provider
            value={{
                currentUser: user,
            }}>
            {props.children}
        </UserContext.Provider>
    );
}

export interface IUserContext {
    currentUser: IUser;
}

export interface IUser extends IAuthProfile, IEmployee {}

export interface IUserProviderProps {
    children: ReactNode;
}
