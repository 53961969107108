import { GraphClient } from '../clients/graph/graph-client';
import { UserFilter } from '../clients/graph/graph-models';
import { IPersonnelData as IPersonnelData } from '../components/tab/tab-models';
import { IAuthContext } from '../contexts/auth-context';

export class PersonnelService {
    private _authContext: IAuthContext;

    private _graphUserIdToUserMap: { [key: string]: IPersonnelData } = {};

    public constructor(authContext: IAuthContext) {
        this._authContext = authContext;
    }

    public async getChatParticipantUserIds(
        chatId: string,
        loadPersonnelDataForParticipants = true,
    ): Promise<string[]> {
        const chatMemberResponse = await GraphClient.getChatMembers(this._authContext, chatId);
        const chatGraphUserIds = chatMemberResponse.value.map((member) => member.userId);

        if (loadPersonnelDataForParticipants) {
            await this.loadPersonnelDataForGraphUserIds(chatGraphUserIds);
        }
        return chatGraphUserIds;
    }

    public async loadPersonnelDataForGraphUserIds(graphUserIds: string[]): Promise<void> {
        const missingGraphUserIds = graphUserIds.filter((userId) => {
            return !(userId in this._graphUserIdToUserMap);
        });

        if (missingGraphUserIds.length > 0) {
            const res = await GraphClient.getUsersById(this._authContext, missingGraphUserIds, [
                UserFilter.Id,
                UserFilter.OnPremisesImmutableId,
                UserFilter.DisplayName,
            ]);

            res.value.forEach((user) => {
                this._graphUserIdToUserMap[user.id] = {
                    displayName: user.displayName,
                    personnelId: user.onPremisesImmutableId!,
                    userId: user.id,
                };
            });
        }
    }

    public getExistingPersonnelIdsFromGraphUserIds(graphUserIds: string[]): string[] {
        return graphUserIds
            .map((graphId) => this.getExistingPersonnelIdFromGraphUserId(graphId))
            .filter((personnelId) => personnelId != null)
            .map((personnelId) => personnelId!);
    }

    public getExistingPersonnelDataFromGraphUserId(graphUserId: string): IPersonnelData | null {
        if (this.userHasPersonnelDataFromGraphUserId(graphUserId)) {
            return this._graphUserIdToUserMap[graphUserId];
        }

        console.log(`Could not get personnel data for graphUserId ${graphUserId}`);
        return null;
    }

    public userHasPersonnelDataFromGraphUserId(graphUserId: string): boolean {
        if (graphUserId in this._graphUserIdToUserMap) {
            return true;
        } else {
            console.log(`No personnel data for graphUserId ${graphUserId}`);
            return false;
        }
    }

    public getExistingPersonnelIdFromGraphUserId(graphUserId: string): string | null {
        const userDetails = this.getExistingPersonnelDataFromGraphUserId(graphUserId);
        return userDetails ? userDetails.personnelId : null;
    }

    public getGraphUserIdsForUsersWithPersonnelData(chatUserIds: string[]): string[] {
        const filteredGraphUserIds = chatUserIds.filter((graphUserId) => {
            return this.userHasPersonnelDataFromGraphUserId(graphUserId);
        });
        return filteredGraphUserIds;
    }
}
