import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    Customizer,
    Dropdown,
    IDropdown,
    IDropdownOption,
    IDropdownStyles,
    mergeStyleSets,
    ResponsiveMode,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import { GroupComplianceStatBox } from './group-compliance-stat-box';
import { IParticipantGroupMembership, ITabProps } from '../tab/tab-models';
import { dropdownDarkTheme } from '../../assets/styles/themes';
import { globalColors } from '../../assets/styles/global-colors';
import logo from '../../assets/img/logo.png';
import { AuthContext } from '../../contexts/auth-context';
import { PersonnelService } from '../../services/personnel-service';
import { GroupService } from '../../services/group-service';
import { IGroupBasic } from '../../clients/groups/groups-model';
import { PersonnelComplianceCard } from './personnel-compliance-card';
import useOnMouseOutside from '../../hooks/onMouseOutside';
import environment from '../../environments/environment';
import groupSort from '../../utils/groupSort';

interface IGroupComplianceStats {
    allCount: number;
    compliantCount: number;
    notCompliantCount: number;
}

enum ComplianceFilterType {
    All,
    Members,
    NonMembers,
}

const COMPLIANCE_STATUS_ALL = 'All';
const COMPLIANCE_STATUS_MEMBERS = 'Members';
const COMPLIANCE_STATUS_NON_MEMBERS = 'Non-Members';

const PARTICIPANTS_REFRESH_PERIOD_MS = 5000;

export function InMeetingTab(props: ITabProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [personnelService] = useState<PersonnelService>(new PersonnelService(authContext));
    const [groupService] = useState<GroupService>(new GroupService(authContext, personnelService));
    const containerRef = useRef(null);
    const dropdownRef = React.createRef<IDropdown>();

    const [isLoadingCompliantGroups, setIsLoadingCompliantGroups] = useState(true);
    const [compliantGroups, setCompliantGroups] = useState<IGroupBasic[]>([]);
    const [chatParticipantGraphUserIds, setChatParticipantGraphUserIds] = useState<string[]>([]);
    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
    const [groupComplianceStats, setGroupComplianceStats] = useState<IGroupComplianceStats>({
        allCount: 0,
        compliantCount: 0,
        notCompliantCount: 0,
    });
    const [isLoadingNewSelectedGroups, setIsLoadingNewSelectedGroups] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [complianceFilter, setComplianceFilter] = useState(ComplianceFilterType.All);

    const handleMouseOutside = () => {
        const el = dropdownRef?.current;
        if (el) {
            el.focus(true);
        }
        const keyInit = {
            key: 'Escape',
            code: 'Escape',
            keyCode: 27,
            altKey: false,
            bubbles: true,
            cancelBubble: false,
            cancelable: true,
            charCode: 0,
            composed: true,
            ctrlKey: false,
            currentTarget: null,
            defaultPrevented: false,
            detail: 0,
            eventPhase: 0,
            isComposing: false,
            isTrusted: true,
            location: 0,
            metaKey: false,
        };

        const keyDownEvent = new KeyboardEvent('keydown', keyInit);
        const keyUpEvent = new KeyboardEvent('keyup', keyInit);
        document.dispatchEvent(keyDownEvent);
        document.dispatchEvent(keyUpEvent);
    };

    useOnMouseOutside(containerRef, handleMouseOutside);

    useEffect(() => {
        async function loadCompliantGroups(): Promise<void> {
            try {
                const newCompliantGroups = await groupService.getCompliantGroupsForUser();
                newCompliantGroups.sort(groupSort);
                setCompliantGroups(newCompliantGroups);
            } catch (e) {
                setErrorMessage(
                    'Failed to retrieve compliant groups, please navigate away from Group Check tab and back to refresh',
                );
            }

            setIsLoadingCompliantGroups(false);
        }

        loadCompliantGroups();
    }, []);

    useEffect(() => {
        async function loadChatParticipants(): Promise<void> {
            try {
                if (props.teamsContext.chatId) {
                    const chatGraphUserIds = await personnelService.getChatParticipantUserIds(
                        props.teamsContext.chatId,
                    );

                    setChatParticipantGraphUserIds(chatGraphUserIds);
                }
            } catch (e) {
                setErrorMessage(
                    'Failed to retrieve chat participants, please navigate away from Group Check tab and back to refresh',
                );
                console.log('error: ', e);
            }
        }

        loadChatParticipants();

        setInterval(() => {
            loadChatParticipants();
        }, PARTICIPANTS_REFRESH_PERIOD_MS);
    }, []);

    useEffect(() => {
        async function updateGroupComplianceStats(): Promise<void> {
            if (chatParticipantGraphUserIds.length > 0 && selectedGroupIds.length > 0) {
                await groupService.setGroupsComplianceData(
                    selectedGroupIds,
                    chatParticipantGraphUserIds,
                );

                let compliantCount = 0;
                let notCompliantCount = 0;

                const filteredGraphUserIds =
                    personnelService.getGraphUserIdsForUsersWithPersonnelData(
                        chatParticipantGraphUserIds,
                    );
                filteredGraphUserIds.forEach((userId) => {
                    const groupComplianceData = groupService.getExistingGroupComplianceDataForUser(
                        userId,
                        selectedGroupIds,
                    );
                    const isUserCompliantInAllGroups = Object.keys(groupComplianceData).every(
                        (groupId) => groupComplianceData[groupId],
                    );
                    if (isUserCompliantInAllGroups) {
                        compliantCount += 1;
                    } else {
                        notCompliantCount += 1;
                    }
                });

                setGroupComplianceStats({
                    allCount: compliantCount + notCompliantCount,
                    compliantCount: compliantCount,
                    notCompliantCount: notCompliantCount,
                });

                setIsLoadingNewSelectedGroups(false);
            }
        }

        updateGroupComplianceStats();
    }, [selectedGroupIds, chatParticipantGraphUserIds]);

    function getDropdownOptions(): IDropdownOption[] {
        return compliantGroups.map((group) => {
            return { key: group.id, text: group.name };
        });
    }

    function getFilterMessage() {
        let filterName = COMPLIANCE_STATUS_ALL;

        switch (complianceFilter) {
            case ComplianceFilterType.Members:
                filterName = COMPLIANCE_STATUS_MEMBERS;
                break;

            case ComplianceFilterType.NonMembers:
                filterName = COMPLIANCE_STATUS_NON_MEMBERS;
                break;
        }

        return `Showing ${filterName}`;
    }

    function getEmployeeComplianceCards(): JSX.Element[] {
        if (chatParticipantGraphUserIds.length === 0 || selectedGroupIds.length === 0) {
            return [];
        }

        const complianceCards: JSX.Element[] = [];

        for (let i = 0; i < chatParticipantGraphUserIds.length; i++) {
            const userId = chatParticipantGraphUserIds[i];

            try {
                const groupCompliance = groupService.getExistingGroupComplianceDataForUser(
                    userId,
                    selectedGroupIds,
                );

                const notCompliantGroupNames = Object.keys(groupCompliance)
                    .filter((groupId) => !groupCompliance[groupId])
                    .map((groupId) => groupService.getExistingGroupData(groupId).group.name);

                const isCompliant = notCompliantGroupNames.length === 0;

                let showUser = false;

                switch (complianceFilter) {
                    case ComplianceFilterType.Members:
                        if (isCompliant) {
                            showUser = true;
                        }
                        break;

                    case ComplianceFilterType.NonMembers:
                        if (!isCompliant) {
                            showUser = true;
                        }
                        break;

                    case ComplianceFilterType.All:
                        showUser = true;
                        break;

                    default:
                        throw Error(`Unknown compliance type: ${complianceFilter}`);
                }

                if (showUser) {
                    const employee =
                        personnelService.getExistingPersonnelDataFromGraphUserId(userId);

                    if (employee) {
                        complianceCards.push(
                            <PersonnelComplianceCard
                                key={userId}
                                employee={employee}
                                compliant={isCompliant}
                                notCompliantGroupNames={notCompliantGroupNames}
                            />,
                        );
                    }
                }
            } catch (e) {}
        }

        complianceCards.sort((complianceCard1, complianceCard2) => {
            const compliant1 = complianceCard1.props['compliant'];
            const compliant2 = complianceCard2.props['compliant'];
            const name1 = (
                complianceCard1.props['employee'] as IParticipantGroupMembership['personnel']
            ).displayName;

            const name2 = (
                complianceCard2.props['employee'] as IParticipantGroupMembership['personnel']
            ).displayName;

            // eslint-disable-next-line eqeqeq
            if (compliant1 == compliant2) {
                if (name1 < name2) {
                    return -1;
                }
                if (name1 > name2) {
                    return 1;
                }
                return 0;
            }
            if (!compliant1) {
                return -1;
            } else {
                return 1;
            }
        });

        return complianceCards;
    }

    function onChange(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption | undefined,
    ): void {
        if (item) {
            const groupId = item.key as string;

            setSelectedGroupIds(
                item.selected
                    ? [...selectedGroupIds, groupId]
                    : selectedGroupIds.filter((activeGroupId) => activeGroupId !== groupId),
            );
            setIsLoadingNewSelectedGroups(true);
        }
    }

    if (errorMessage) {
        return <div className={styles.errorMessage}>{errorMessage}</div>;
    }

    return (
        <div ref={containerRef} className={styles.container}>
            <div className={styles.contentContainer}>
                <p className={styles.headerLabel}>
                    <u>Select Group:</u>
                </p>
                <div className={styles.dropdownContainer}>
                    <Customizer settings={{ theme: dropdownDarkTheme }}>
                        {isLoadingCompliantGroups ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <Dropdown
                                options={getDropdownOptions()}
                                styles={dropdownStyles}
                                responsiveMode={ResponsiveMode.large}
                                onChange={onChange}
                                componentRef={dropdownRef}
                                multiSelect
                            />
                        )}
                    </Customizer>
                </div>
                {isLoadingNewSelectedGroups ? (
                    <Spinner size={SpinnerSize.large} />
                ) : (
                    selectedGroupIds.length > 0 && (
                        <>
                            <div className={styles.statBoxesContainer}>
                                <GroupComplianceStatBox
                                    label={COMPLIANCE_STATUS_ALL}
                                    count={groupComplianceStats?.allCount}
                                    color={globalColors.totalCountPurple}
                                    onClick={() => setComplianceFilter(ComplianceFilterType.All)}
                                />
                                <GroupComplianceStatBox
                                    label={COMPLIANCE_STATUS_MEMBERS}
                                    count={groupComplianceStats?.compliantCount}
                                    color={globalColors.compliantGreen}
                                    onClick={() =>
                                        setComplianceFilter(ComplianceFilterType.Members)
                                    }
                                />
                                <GroupComplianceStatBox
                                    label={COMPLIANCE_STATUS_NON_MEMBERS}
                                    count={groupComplianceStats?.notCompliantCount}
                                    color={globalColors.notCompliantRed}
                                    onClick={() =>
                                        setComplianceFilter(ComplianceFilterType.NonMembers)
                                    }
                                />
                            </div>
                            <div className={styles.filterLabel}>{getFilterMessage()}</div>
                            <div className={styles.employeeComplianceCardsContainer}>
                                {getEmployeeComplianceCards()}
                            </div>
                        </>
                    )
                )}
            </div>
            <div className={styles.personnelLogoContainer}>
                <img className={styles.logo} src={logo} />
                <div className={styles.logoTextContainer}>
                    <h2 className={styles.logoTitle}>Personnel</h2>
                    <p className={styles.feedbackLabel}>
                        Submit feedback{' '}
                        <a
                            className={styles.url}
                            href={environment.feedbackFormUrl}
                            target='_blank'
                            rel='noopener noreferrer'>
                            <u>here.</u>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    errorMessage: {
        color: globalColors.errorRed,
    },
    container: {
        display: 'flex',
        maxWidth: '260px',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'space-between',
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    headerLabel: {
        color: globalColors.white,
        fontSize: '18px',
        lineHeight: '26px',
        marginTop: '0px',
        marginBottom: '10px',
    },
    dropdownContainer: {
        display: 'flex',
    },
    statBoxesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '10px',
        maxWidth: '260px',
    },
    employeeComplianceCardsContainer: {
        marginTop: '10px',
    },
    personnelLogoContainer: {
        display: 'flex',
        paddingBottom: '20px',
        alignSelf: 'center',
    },
    logo: {
        height: '25px',
        width: '25px',
        paddingRight: '15px',
        paddingTop: '14px',
    },
    logoTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    logoTitle: {
        fontSize: '16px',
        margin: '0',
        alignSelf: 'center',
        color: globalColors.white,
    },
    feedbackLabel: {
        color: globalColors.white,
        margin: '0',
        alignSelf: 'center',
    },
    url: {
        color: 'inherit',
    },
    filterLabel: {
        color: globalColors.white,
        fontSize: '18px',
        lineHeight: '26px',
        marginTop: '4px',
        marginBottom: '0px',
    },
});

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: 260,
    },
    caretDown: {
        color: globalColors.white,
    },
};
