import { NeutralColors, SharedColors } from '@fluentui/theme';

export const globalColors = {
    backgroundGray: 'rgb(61, 61, 61)',
    selectedGray: 'rgb(80, 80, 80)',
    hoverGray: 'rgb(100, 100, 100)',
    compliantGreen: SharedColors.greenCyan10,
    notCompliantRed: SharedColors.red10,
    totalCountPurple: '#7479DC',
    white: NeutralColors.white,
    black: NeutralColors.black,
    errorRed: SharedColors.red10,
    indigo: '#6264A7',
    hyperlinkDarkBlue: '#0000EE',
    hyperlinkLightBlue: '#007BFF',
};
