import React from 'react';
import { mergeStyleSets, TooltipHost } from 'office-ui-fabric-react';
import { globalColors } from '../../assets/styles/global-colors';

export interface IPersonnelComplianceCardProps {
    employee: {
        displayName: string;
        personnelId: string;
    };
    compliant: boolean;
    notCompliantGroupNames: string[];
}

export function PersonnelComplianceCard(props: IPersonnelComplianceCardProps): JSX.Element {
    const notCompliantGroupDisplayString =
        props.notCompliantGroupNames.length === 0
            ? ''
            : `Not a compliant member in: ${props.notCompliantGroupNames.join(', ')}`;

    const displayName = props.employee.displayName;

    return (
        <TooltipHost content={notCompliantGroupDisplayString} id={props.employee.personnelId}>
            <div className={styles.container}>
                <div
                    className={[
                        styles.complianceColor,
                        props.compliant ? styles.compliantColor : styles.notCompliantColor,
                    ].join(' ')}
                />
                <div title={displayName} className={styles.employeeContainer}>
                    {displayName}
                </div>
            </div>
        </TooltipHost>
    );
}

const styles = mergeStyleSets({
    container: {
        display: 'flex',
        backgroundColor: globalColors.backgroundGray,
        marginBottom: '4px',
        height: '32px',
    },
    complianceColor: {
        display: 'flex',
        width: '8px',
        marginRight: '10px',
    },
    compliantColor: {
        backgroundColor: globalColors.compliantGreen,
    },
    notCompliantColor: {
        backgroundColor: globalColors.notCompliantRed,
    },
    employeeContainer: {
        alignItems: 'center',
        color: globalColors.white,
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        paddingTop: '4px',
    },
});
