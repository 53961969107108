import React, { useEffect, useState } from 'react';
import './app.css';
import * as microsoftTeams from '@microsoft/teams-js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Privacy } from './privacy';
import { TermsOfUse } from './terms-of-use';
import { Tab } from './tab/tab';
import AuthContextProvider from '../contexts/auth-context';
import UserContextProvider from '../contexts/user-context';
import { Config } from './config';
import { initializeIcons } from '@fluentui/react';
import { globalStyles } from '../assets/styles/global-styles';
import config from '../environments/environment';
import TeamsContextProvider from '../contexts/teams-context';
import environment from '../environments/environment';
import addOneDsTelemetry from '@sovereign/sovereign-ui-telemetry';

export function App(): JSX.Element {
    microsoftTeams.initialize();
    initializeIcons();

    if (environment.applicationInsightsKey) {
        addOneDsTelemetry(
            environment.applicationInsightsKey,
            environment.applicationInsightsRoleName,
        );
    }

    return (
        <TeamsContextProvider>
            <AuthContextProvider config={config.aadConfig}>
                <UserContextProvider>
                    <div className={globalStyles.fontStyles}>
                        <Router>
                            <Route exact path='/privacy' component={Privacy} />
                            <Route exact path='/termsofuse' component={TermsOfUse} />
                            <Route exact path='/tab' component={Tab} />
                            <Route exact path='/config' component={Config} />
                        </Router>
                    </div>
                </UserContextProvider>
            </AuthContextProvider>
        </TeamsContextProvider>
    );
}
