export enum GraphScope {
    ChatReadWrite = 'Chat.ReadWrite',
    UserReadAll = 'User.Read.All',
    OnlineMeetingsReadWrite = 'OnlineMeetings.ReadWrite',
}

export enum UserFilter {
    Id = 'id',
    DisplayName = 'displayName',
    OnPremisesImmutableId = 'onPremisesImmutableId',
}

export interface IUser {
    id: string;
    businessPhones: string[];
    displayName: string;
    givenName: string;
    jobTitle: string;
    mail: string;
    mobilePhone?: string;
    officeLocation?: string;
    preferredLanguage: string;
    surname: string;
    userPrincipalName: string;
    onPremisesImmutableId?: string;
}

export interface IGetUsersByIdResponse {
    '@odata.context': string;
    value: IUser[];
}

export interface IChatMember {
    '@odata.type': string;
    id: string;
    roles: string[];
    displayName: string;
    userId: string;
    email?: string;
    tenantId: string;
    visibleHistoryStartDateTime: string;
}

export interface IGetChatMembersResponse {
    '@odata.context': string;
    '@odata.count': number;
    value: IChatMember[];
}
