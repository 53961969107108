import React from 'react';
import './app.css';

export function Privacy(): JSX.Element {
    return (
        <>
            <h1>Privacy Statement</h1>
        </>
    );
}
