import React, { useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import environment from '../environments/environment';
import { setTheme, themeColors } from '../assets/styles/themes';

export function Config(): JSX.Element {
    const [isDoneLoadingContext, setIsDoneLoadingContext] = useState(false);

    useEffect(() => {
        microsoftTeams.getContext((context: microsoftTeams.Context) => {
            setTheme(context.theme);
            setIsDoneLoadingContext(true);
        });
    }, []);

    useEffect(() => {
        microsoftTeams.settings.setValidityState(true);

        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            microsoftTeams.settings.setSettings({
                contentUrl: environment.reactAppConfig.tabUrl,
                suggestedDisplayName: `Group Check${environment.teamsAddinDisplaySuffix}`,
            } as microsoftTeams.settings.Settings);

            saveEvent.notifySuccess();
        });
    }, []);

    return (
        <>
            {isDoneLoadingContext && (
                <div style={{ color: themeColors.text }}>
                    <p>To use Group Check:</p>
                    <p>
                        1) Select from any combination of the Groups of which you&#39;re a member.
                    </p>
                    <p>
                        2) Make informed decisions about what program information to share based on
                        your audience.
                    </p>
                    <p>
                        For more information on your group membership, visit:{' '}
                        <a
                            style={{ color: themeColors.hyperlink }}
                            href='https://personnel.microsoft.com/groups'
                            target='_blank'
                            rel='noopener noreferrer'>
                            https://personnel.microsoft.com/groups
                        </a>
                    </p>
                </div>
            )}
        </>
    );
}
