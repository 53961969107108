import React, { useContext, useEffect, useState } from 'react';
import '../app.css';
import * as microsoftTeams from '@microsoft/teams-js';
import { RouteComponentProps } from 'react-router-dom';
import { InMeetingTab } from '../in-meeting-tab/in-meeting-tab';
import { PreMeetingTab } from '../pre-meeting-tab/pre-meeting-tab';
import { IGroupBasic } from '../../clients/groups/groups-model';
import { AuthContext } from '../../contexts/auth-context';
import { PersonnelService } from '../../services/personnel-service';
import { GroupService } from '../../services/group-service';
import { setTheme } from '../../assets/styles/themes';
import groupSort from '../../utils/groupSort';

export function Tab(props: RouteComponentProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [personnelService] = useState<PersonnelService>(new PersonnelService(authContext));
    const [groupService] = useState<GroupService>(new GroupService(authContext, personnelService));

    const [invokedGroupIds, setInvokedGroupIds] = useState<string[]>([]);
    const [compliantGroups, setCompliantGroups] = useState<IGroupBasic[]>([]);
    const [teamsContext, setTeamsContext] = useState<microsoftTeams.Context>();
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        async function loadCompliantGroups(): Promise<void> {
            try {
                const newCompliantGroups = await groupService.getCompliantGroupsForUser();
                newCompliantGroups.sort(groupSort);
                setCompliantGroups(newCompliantGroups);
            } catch (e) {
                setErrorMessage('Failed to retrieve compliant groups');
            }
        }

        loadCompliantGroups();
    }, []);

    useEffect(() => {
        const newInvokedGroupIds = new URLSearchParams(props.location.search)
            .get('groupIds')
            ?.split(',');

        setInvokedGroupIds(newInvokedGroupIds ? newInvokedGroupIds : []);
    }, []);

    useEffect(() => {
        microsoftTeams.getContext((context: microsoftTeams.Context) => {
            setTheme(context.theme);
            setTeamsContext(context);
        });
    }, []);

    if (!teamsContext) {
        return <></>;
    }
    if (teamsContext?.frameContext === microsoftTeams.FrameContexts.sidePanel) {
        return (
            <InMeetingTab
                teamsContext={teamsContext}
                invokedGroupIds={invokedGroupIds}
                compliantGroups={compliantGroups}
                errorMessage={errorMessage}
            />
        );
    } else {
        return (
            <PreMeetingTab
                teamsContext={teamsContext}
                invokedGroupIds={invokedGroupIds}
                compliantGroups={compliantGroups}
                errorMessage={errorMessage}
            />
        );
    }
}
